import {UserAgent, Registerer, RegistererState, RegistererOptions, SessionState, Inviter, Grammar} from "sip.js";
import {SessionDescriptionHandler} from "sip.js/lib/platform/web/session-description-handler";
import {Invitation, RequestPendingError} from "sip.js/lib/api";

const STATUS_OUTGOING = 'outgoing';
const STATUS_INCOMING = 'incoming';

var ctxSip = {
    Sessions: [],
    userAgent: null,
    delegate: null,
    registerer: null,
    realm: null,
    reconnectionDelay: 15,
    keepAliveServerTimeout: null,
    isUseOpenSips: false,
    sessionWasEstablished: false,
    earlyMedia: new Audio(),
    sessionsAudioCtx: {},
    isMuted: false,
    terminationOfConference: false,
    isConference: false,
    connectionErrorNotified: false,
    conferenceAudioContext: null,
    speakerDeviceId: 'default',
    microphoneId: 'default',
    connectRequested: false,

    connectUA: function (user) {
        const ctx = this;
        const uri = UserAgent.makeURI('sip:' + user.User.toString() + '@' + user.Realm);
        this.realm = user.Realm;
        this.isUseOpenSips = user.isUseOpenSips;

        if (!uri) {
            console.error("Failed to create URI");
            return
        }

        let config = {
            logLevel: "error",
            uri: uri,
            authorizationUsername: user.User,
            authorizationPassword: user.Pass,
            displayName: user.Display,
            transportOptions: {
                server: user.WSServer,
                keepAliveInterval: 90,
                connectionTimeout: 90
            },
            register: true,
            delegate: ctx.initDelegate(),
            // tempGruu: 'sipmvpl.au.voipcloud.online',
            // pubGruu: 'sipmvpl.au.voipcloud.online',

            // contactName: '104571', // This should be used to prevent random username in Contact header when sending REGISTER
            // contactName: user.User.toString(), // This should be used to prevent random username in Contact header when sending REGISTER
            // viaHost: 'sipmvpl.au.voipcloud.online', // This should be used to prevent random domain in Contact header when sending REGISTER
            // viaHost: user.Realm, // This should be used to prevent random domain in Contact header when sending REGISTER
            contactParams: {
                // transport: "" // OpenSIPS returns Contact header without transport. Default is "ws"
            }
        }

        if (user.isUseOpenSips) {
            config.contactName = user.User.toString();
            config.transportOptions.keepAliveInterval = 0;
        }

        this.userAgent = new UserAgent(config);

        if (!user.isUseOpenSips) {
            this.userAgent.contact.uri.user = user.User.toString()
        }

        this.connectRequested = true

        this.notifyConnectionStart()

        this.userAgent.start()
            .then(() => {
                ctx.registerer = new Registerer(ctx.userAgent, {
                    expires: 120,
                    refreshFrequency: 90
                });
                this.registerer.stateChange.addListener((state) => {
                    switch (state) {
                        case RegistererState.Registered:
                            ctx.notifyRegisterSuccess()

                            if (!user.isUseOpenSips) {
                                ctx.serverHeartBeat();
                            }
                            break;
                    }
                });

                ctx.registerer.register({
                    requestDelegate: {
                        onReject(response) {
                            ctx.notifyConnectionError()
                            console.error('Registration Error. An Error occurred registering your phone. Server return ' + response.message.statusCode);
                        }
                    }
                }).catch(() => {
                    ctx.notifyConnectionError()
                    console.error('Registration Error. An Error occurred registering your phone. Failed send REGISTER');
                });

                document.addEventListener("socketConnectionDisconnect", () => {
                    this.notifyConnectionError()
                    if (this.userAgent.transport.ws) {
                        console.error('Connection error. Close WS')
                        this.userAgent.transport.ws.close()
                    }
                })
            })
            .catch((error) => {
                console.error(error)
                ctx.notifyConnectionError()
            })
    },

    serverHeartBeat: function() {
        const ctx = this;
        const ws = this.userAgent.transport.ws;

        function keepAliveServer() {
            clearTimeout(ctx.keepAliveServerTimeout)

            ctx.keepAliveServerTimeout = setTimeout(() => {
                ctx.notifyConnectionError()
                ws.close()
            }, 100000)
        }

        ws.addEventListener("message", function (ev) {
            if (/^(\r\n)+$/.test(ev.data)) {
                keepAliveServer()
            }
        });
        keepAliveServer()
    },

    disconnectUA: function () {
        if (this.userAgent) {
            this.connectRequested = false
            this.userAgent.stop();
        }
    },

    acceptCall : function(sessionId) {
        const session = this.Sessions[sessionId];

        if (!session) {
            return
        }

        session.accept({
            sessionDescriptionHandlerOptions: {
                constraints: {
                    audio: {
                        deviceId: this.microphoneId
                    },
                    video: false
                },
                iceCheckingTimeout: 500
            }
        }).catch(this.sessionErrHandler)
    },

    call: function(target, silent = false, callerId = null) { // silent means no events will be emitted except silentSessionTerminated
        const uriTarget = this.makeURIWithPhoneNumber(target)
        console.log(target)
        console.log(uriTarget)

        var preferredCallerId = callerId ? callerId : 'anonymous'
        var session = new Inviter(this.userAgent, uriTarget, {
            earlyMedia: true, //play sound from Asterisk, also need add "inband_progress = yes" in Asterisk configs
            extraHeaders: [
                'X-Preffered-CallerID: ' + preferredCallerId
            ]
        });
        session.invite({
            sessionDescriptionHandlerOptions: {
                constraints: {
                    audio: {
                        deviceId: this.microphoneId
                    },
                    video: false
                },
                iceCheckingTimeout: 500
            }
        }).catch(this.sessionErrHandler)

        session = this.initSession(session, STATUS_OUTGOING, silent, preferredCallerId);
        session.stateChange.addListener((newState) => {
            switch (newState) {
                case SessionState.Establishing:
                    if (!silent) {
                        const eventOutgoingCallEstablishing = new CustomEvent('outgoingCallEstablishing');
                        document.dispatchEvent(eventOutgoingCallEstablishing);

                        session.sessionDescriptionHandler.remoteMediaStream.onaddtrack = () => {
                            document.dispatchEvent(new CustomEvent('stopRingSound'));
                            this.playEarlyMedia(session.sessionDescriptionHandler.remoteMediaStream)
                        }
                    }
                    break;
                case SessionState.Established:
                    console.log('session accepted');
                    console.log(session.ctxid);

                    if (!silent) {
                        this.stopEarlyMedia()
                        this.sessionEstablishedHandler(session)
                    }
                    break;
                case SessionState.Terminated:
                    console.log('session terminated');
                    console.log(session.ctxid);
                    if (!silent) {
                        this.stopEarlyMedia()
                    }
                    this.sessionTerminatedHandler(session)
                    break;
            }
        });
    },

    hangUp: function (sessionId) {
        var session = this.Sessions[sessionId];
        this.terminate(session);
    },

    initSession: function (session, sessionStatus, silent = false, preferredCallerId = null) {
        session.displayName = session.remoteIdentity.displayName || session.remoteIdentity.uri.user;
        session.ctxid = Math.random().toString(36).substr(2, 9);
        session.isOnHold = false
        session.silent = silent

        console.log('newSession');
        console.log(session.ctxid);

        let number = session.remoteIdentity.uri.user;
        let callReceivedNumber = '';
        let callReceivedName = '';
        let callReceivedQueue = '';

        if (sessionStatus === 'incoming') {
            const headers = session.request.headers;
            callReceivedNumber = headers["X-Received-On-Exten"] ? headers["X-Received-On-Exten"][0].raw : ''
            callReceivedName = headers["X-Received-On-Name"] ? headers["X-Received-On-Name"][0].raw : ''
            callReceivedQueue = headers["X-Received-On-Queue"] ? headers["X-Received-On-Queue"][0].raw : ''
        }

        var sessionData = {
            'id':  session.ctxid,
            'number': number,
            'display_name': session.displayName,
            'status': sessionStatus,
            'call_received_number': callReceivedNumber,
            'call_received_name': callReceivedName,
            'call_received_queue': callReceivedQueue,
            'preferred_caller_id': preferredCallerId
        };

        this.Sessions[session.ctxid] = session;

        this.callInit(sessionData);

        if (sessionStatus === STATUS_INCOMING) {
            this.incomingCallInit(sessionData);
        }

        if (sessionStatus === STATUS_OUTGOING && !silent) {
            this.outgoingCallInit(sessionData);
        }

        return session;
    },

    initDelegate: function () {
        this.delegate = {
            onInvite: (invitation) => {
                const sessionsLength = Object.keys(this.Sessions).length;

                if (sessionsLength >= 2) {
                    invitation.reject();
                    return;
                }

                const session = this.initSession(invitation, STATUS_INCOMING);
                session.stateChange.addListener((newState) => {
                    switch (newState) {
                        case SessionState.Established:
                            this.sessionEstablishedHandler(session);
                            break;
                        case SessionState.Terminated:
                            this.sessionTerminatedHandler(session);
                            break;
                    }
                })
            },
            onDisconnect: (error) => {
                console.log('Disconnected');
                this.Sessions.forEach(function (session) {
                    this.terminate(session);
                })

                if (this.registerer) {
                    console.log('Unregistering...');
                    this.registerer.unregister().catch((e) => {
                        console.error("Error occurred unregistering after connection with server was lost.")
                        console.error(e.toString())
                    });
                }
                // Only attempt to reconnect if network/server dropped the connection.
                if (error) {
                    console.error(error);
                    this.notifyConnectionError()
                    this.attemptReconnection()
                }
            },
            onNotify: (notification) =>
            {
                notification.accept()
            }
        }

        return this.delegate;
    },

    attemptReconnection(reconnectionAttempt = 1) {
        const ctx = this;

        if (!this.connectRequested) {
            console.log("Reconnection not currently desired");
            return; // If intentionally disconnected, don't reconnect
        }

        console.log(`Reconnection attempt ${reconnectionAttempt} - trying in ${this.reconnectionDelay} seconds`);
        this.notifyConnectionStart()

        setTimeout(() => {
            if (!this.connectRequested) {
                console.log(`Reconnection attempt ${reconnectionAttempt} - aborted`);
                return; // If intentionally disconnected, don't reconnect.
            }

            ctx.userAgent
                .reconnect()
                .then(() => {
                    console.log(`Reconnection attempt ${reconnectionAttempt} - succeeded`);
                    console.log("Registering...");
                    ctx.registerer.register().then(() => {
                        ctx.notifyRegisterSuccess()
                    }).catch((e) => {
                        console.error("Error occurred registering after connection with server was obtained.")
                        console.error(e.toString())
                    });
                })
                .catch((error) => {
                    console.log(`Reconnection attempt ${reconnectionAttempt} - failed`);
                    console.error(error.message);
                    ctx.attemptReconnection(++reconnectionAttempt);
                });
        }, reconnectionAttempt === 1 ? 0 : ctx.reconnectionDelay * 1000);
    },

    hold: function (session) {
        return this.setHold(session, true)
    },

    unhold: function (session) {
        return this.setHold(session, false)
    },

    // hold - Hold on if true, off if false.
    setHold: function (session, hold) {
        // Just resolve if we are already in correct state
        if (session.isOnHold === hold) {
            return Promise.resolve();
        }

        if (session.state === SessionState.Terminated) {
            console.log('session terminated')
            return;
        }

        const sessionDescriptionHandler = session.sessionDescriptionHandler;
        if (!(sessionDescriptionHandler instanceof SessionDescriptionHandler)) {
            throw new Error("Session's session description handler not instance of SessionDescriptionHandler.");
        }

        const ctx = this;
        const options = {
            requestDelegate: {
                onAccept: () => {
                    session.isOnHold = hold;
                }
            }
        };

        const sessionDescriptionHandlerOptions = session.sessionDescriptionHandlerOptionsReInvite;
        sessionDescriptionHandlerOptions.hold = hold;
        session.sessionDescriptionHandlerOptionsReInvite = sessionDescriptionHandlerOptions;

        // Send re-INVITE
        return session
            .invite(options)
            .then(() => {
                // preemptively enable/disable tracks
                this.enableReceiverTracks(session, !hold);
                if (!this.isMuted && !hold) {
                    this.enableSenderTracks(session, !hold);
                }
                if (this.delegate && this.delegate.onCallHold) {
                    this.delegate.onCallHold(session.isOnHold);
                }
            })
    },

    // mute - true, unmute - false
    mute: function (mute) {
        Object.values(this.sessionsAudioCtx).forEach((audioCtx) => {
            let gainNode = audioCtx.gain_node

            if (gainNode) {
                gainNode.gain.value = mute ? 0 : 1
                this.isMuted = mute
            }
        })
    },

    sendDTMF: function(symbol, sessionId) {
        const session = this.Sessions[sessionId];
        const dtmf = symbol;
        const duration = 120;
        const body = {
            contentDisposition: "render",
            contentType: "application/dtmf-relay",
            content: "Signal=" + dtmf + "\r\nDuration=" + duration
        };
        const requestOptions = { body };
        return session.info({ requestOptions });
    },

    blindTransfer: function(target, sessionId) {
        const ctx = this;
        const session = this.Sessions[sessionId];
        const uriTarget = this.makeURIWithPhoneNumber(target)

        console.log(target);
        session.refer(
            uriTarget,
            {
                onNotify: function (notification) {
                    if (notification.request.body.includes("200 OK")) {
                        notification.accept().then(() => {
                            ctx.terminate(session)
                        })
                    } else {
                        notification.accept()
                    }
                },
            }
        );
    },

    attendedTransferCall: function (replacementsSessionId, sessionId) {
        const replacementSession = this.Sessions[replacementsSessionId];
        const session = this.Sessions[sessionId];

        if (replacementSession.state === SessionState.Established) {
            session.refer(replacementSession)
            this.terminate(session);
        } else {
            const uriTarget = replacementSession.outgoingRequestMessage.toURI;
            this.terminate(replacementSession)

            session.refer(uriTarget);
            this.terminate(session);
        }
    },

    createConference: function () {
        // create conference by mixing audio
        const sessions = Object.values(this.Sessions)
        const receivedTracks = []
        this.isConference = true

        sessions.forEach((session) => {
            if (session) {
                receivedTracks.push(session.sessionDescriptionHandler.peerConnection.getReceivers()[0].track)
            }
        });

        this.conferenceAudioContext = this.createAudioContext()

        sessions.forEach((session) => {
            if (session) {
                let mixedOutput = this.conferenceAudioContext.createMediaStreamDestination()

                // mix received tracks
                let receiver = session.sessionDescriptionHandler.peerConnection.getReceivers()[0]
                receivedTracks.forEach((track) => {
                    if (receiver.track.id !== track.id) {
                        let sourceStream = this.conferenceAudioContext.createMediaStreamSource(new MediaStream([track]))
                        sourceStream.connect(mixedOutput)
                    }
                });

                // mixing your voice with all received audio
                let sender = session.sessionDescriptionHandler.peerConnection.getSenders()[0]
                let sourceStream = this.conferenceAudioContext.createMediaStreamSource(new MediaStream([sender.track]))
                sourceStream.connect(mixedOutput)
                sender.replaceTrack(mixedOutput.stream.getTracks()[0])
            }
        })
    },

    splitConference: function () {
        const sessions = Object.values(this.Sessions)
        this.isConference = false

        // for each session replace sender track from mix to initial
        sessions.forEach((session) => {
            let sender = session.sessionDescriptionHandler.peerConnection.getSenders()[0]
            sender.track.stop()
            sender.replaceTrack(this.sessionsAudioCtx[session.ctxid].init_sender_track)
        })

        this.conferenceAudioContext.close().catch((error) => {
            console.error(error)
        })
    },

    terminateConference: function () {
        const sessions = Object.values(this.Sessions)
        let promises = []

        this.isConference = false
        this.terminationOfConference = true

        sessions.forEach((session) => {
            promises.push(this.terminate(session))
        })

        Promise.allSettled(promises).then(() => {
            this.terminationOfConference = false
            document.dispatchEvent(new CustomEvent('conferenceTerminated'))
        })

        this.conferenceAudioContext.close().catch((error) => {
            console.error(error)
        })
    },

    sessionEstablishedHandler: async function (session) {
        // if incoming
        if (session instanceof Invitation) {
            this.incomingCallOver(session.ctxid, true);
        }

        // if outgoing
        if (session instanceof Inviter) {
            this.outgoingCallOver(false);
        }

        this.sessionWasEstablished = true;

        const eventSessionEstablished = new CustomEvent('sessionEstablished', {"detail": { sessionId: session.ctxid }});
        document.dispatchEvent(eventSessionEstablished);

        const remoteAudioElement = new Audio()
        const remoteStream = session.sessionDescriptionHandler.remoteMediaStream;

        try {
            await remoteAudioElement.setSinkId?.(this.speakerDeviceId);
        } catch (e) {
            console.error(e)
        }

        remoteAudioElement.srcObject = remoteStream;
        remoteAudioElement.autoplay = true;
        remoteAudioElement.play().catch((error) => {
            console.error(`Failed to play remote media`);
            console.error(error.message);
        })

        const sessionDescriptionHandler = session.sessionDescriptionHandler;
        if (!(sessionDescriptionHandler instanceof SessionDescriptionHandler)) {
            throw new Error("Session's session description handler not instance of SessionDescriptionHandler.");
        }
        const peerConnection = sessionDescriptionHandler.peerConnection;
        if (!peerConnection) {
            throw new Error("Peer connection closed.");
        }

        // add gainNode to sender track to be able to mute sender track without using track.enabled
        const audioContext = this.createAudioContext()
        const sender = peerConnection.getSenders()[0]
        const gainNode = audioContext.createGain()
        const stream = new MediaStream([sender.track])
        const audioSource = audioContext.createMediaStreamSource(stream)
        const audioDestination = audioContext.createMediaStreamDestination()
        audioSource.connect(gainNode)
        gainNode.connect(audioDestination)
        gainNode.gain.value = this.isMuted ? 0 : 1
        sender.replaceTrack(audioDestination.stream.getTracks()[0])

        this.sessionsAudioCtx[session.ctxid] = {
            remote_audio: remoteAudioElement,
            gain_node: gainNode,
            audio_source: audioSource,
            init_sender_track: audioDestination.stream.getTracks()[0],
            audio_ctx: audioContext
        }
    },

    sessionTerminatedHandler: function (session) {
        if (!session.silent) {
            // if incoming
            if (session instanceof Invitation) {
                this.incomingCallOver(session.ctxid, this.sessionWasEstablished);
            }

            // if outgoing
            if (session instanceof Inviter) {
                this.outgoingCallOver(session instanceof Inviter && !session.isCanceled && !this.sessionWasEstablished);
            }
        }

        if (Object.keys(this.Sessions).length === 1) {
            this.sessionWasEstablished = false;
            this.isMuted = false
        }

        const sessionAudioCtx = this.sessionsAudioCtx[session.ctxid]

        if (sessionAudioCtx) {
            const remoteAudio = sessionAudioCtx.remote_audio
            const audioSource = sessionAudioCtx.audio_source
            const audioCtx = sessionAudioCtx.audio_ctx

            remoteAudio.srcObject = null
            remoteAudio.pause()
            remoteAudio.remove()

            audioSource.mediaStream.getTracks().forEach((track) => {
                track.stop()
            })

            audioCtx.close().catch((error) => {
                console.error(error)
            })
        }

        delete this.Sessions[session.ctxid]
        delete this.sessionsAudioCtx[session.ctxid]

        if (this.isConference && Object.keys(this.Sessions).length === 1) {
            this.splitConference()
        }

        if (session.silent) {
            const eventSessionTerminated = new CustomEvent('silentSessionTerminated', {"detail": {number: session.displayName}});
            document.dispatchEvent(eventSessionTerminated);
            return
        }

        // not send a "sessionTerminated" event for each session if user (who started conference) clicks hang up btn during conference, on event will be send after all sessions are terminated
        if (!this.terminationOfConference) {
            const eventSessionTerminated = new CustomEvent('sessionTerminated', {"detail": {sessionId: session.ctxid, incomingCallCanceled: session instanceof Invitation && session.isCanceled}});
            document.dispatchEvent(eventSessionTerminated);
        }
    },

    enableReceiverTracks: function (session, enable) {
        if (!session) {
            throw new Error("Session does not exist.");
        }
        const sessionDescriptionHandler = session.sessionDescriptionHandler;
        if (!(sessionDescriptionHandler instanceof SessionDescriptionHandler)) {
            throw new Error("Session's session description handler not instance of SessionDescriptionHandler.");
        }
        const peerConnection = sessionDescriptionHandler.peerConnection;
        if (!peerConnection) {
            throw new Error("Peer connection closed.");
        }
        peerConnection.getReceivers().forEach((receiver) => {
            if (receiver.track) {
                receiver.track.enabled = enable;
            }
        });
    },

    enableSenderTracks: function (session, enable) {
        if (!session) {
            throw new Error("Session does not exist.");
        }

        const sessionDescriptionHandler = session.sessionDescriptionHandler;
        if (!(sessionDescriptionHandler instanceof SessionDescriptionHandler)) {
            throw new Error("Session's session description handler not instance of SessionDescriptionHandler.");
        }
        const peerConnection = sessionDescriptionHandler.peerConnection;
        if (!peerConnection) {
            throw new Error("Peer connection closed.");
        }
        peerConnection.getSenders().forEach((sender) => {
            if (sender.track) {
                sender.track.enabled = enable;
            }
        });
    },

    terminate: function (session) {
        console.log('Terminating...');
        if (!session) {
            console.error("Session does not exist.");
            return;
        }

        switch (session.state) {
            case SessionState.Initial:
                if (session instanceof Inviter) {
                    console.log('initial 1')
                    return session.cancel();
                }
                else if (session instanceof Invitation) {
                    console.log('initial 2')
                    return session.reject();
                }
                else {
                    console.error("Unknown session type.");
                }
                break;
            case SessionState.Establishing:
                if (session instanceof Inviter) {
                    console.log('establishing 1')
                    return session.cancel();
                }
                else if (session instanceof Invitation) {
                    console.log('establishing 2')
                    return session.reject();
                }
                else {
                    console.error("Unknown session type.");
                }
                break;
            case SessionState.Established:
                return session.bye();
            case SessionState.Terminating:
                break;
            case SessionState.Terminated:
                break;
            default:
                throw new Error("Unknown state");
        }
    },

    makeURIWithPhoneNumber: function (number) {
        return Grammar.URIParse('sip:' + number + '@' + this.realm);
    },

    callInit: function(sessionData) {
        const eventCallInit = new CustomEvent('callInit', {"detail": { sessionData: sessionData}});
        document.dispatchEvent(eventCallInit);
    },

    incomingCallInit: function(sessionData) {
        const eventIncomingCallInit = new CustomEvent('incomingCallInit', {"detail": { sessionData: sessionData}});
        document.dispatchEvent(eventIncomingCallInit);
    },

    incomingCallOver: function(sessionId, sessionWasAnswered) {
        const eventIncomingCallOver = new CustomEvent('incomingCallOver', {"detail": {sessionId, sessionWasAnswered}});
        document.dispatchEvent(eventIncomingCallOver);
    },

    outgoingCallInit: function(sessionData) {
        const eventOutgoingCallInit = new CustomEvent('outgoingCallInit', {"detail": { sessionData: sessionData}});
        document.dispatchEvent(eventOutgoingCallInit);
    },

    outgoingCallOver: function(sessionWasNotAnswered) {
        const eventOutgoingCallInit = new CustomEvent('outgoingCallOver', {"detail": {sessionWasNotAnswered}});
        document.dispatchEvent(eventOutgoingCallInit);
    },

    playEarlyMedia: async function(stream) {
        this.earlyMedia.autoplay = true
        this.earlyMedia.playbackRate = 1;
        this.earlyMedia.currentTime = 0;
        this.earlyMedia.volume = 1;

        try {
            await this.earlyMedia.setSinkId?.(this.speakerDeviceId);
        } catch (e) {
            console.error(e)
        }

        this.earlyMedia.srcObject = stream;
    },

    stopEarlyMedia: function() {
        if (this.earlyMedia.srcObject) {
            this.earlyMedia.pause();
            this.earlyMedia.currentTime = 0;
            this.earlyMedia.srcObject = null;
        }
    },

    notifyConnectionError: function() {
        if (!this.connectionErrorNotified) {
            this.connectionErrorNotified = true
            document.dispatchEvent(new CustomEvent('connectionError'))
        }
    },

    notifyRegisterSuccess: function() {
        this.connectionErrorNotified = false
        document.dispatchEvent(new CustomEvent('registerSuccess'))
    },

    notifyConnectionStart: function() {
        document.dispatchEvent(new CustomEvent('connectionStart'))
    },

    sessionErrHandler: function(err) {
        if (err.name === "NotFoundError" || err.name === "NotAllowedError") {
            document.dispatchEvent(new CustomEvent('errorNoMic'));
        }
    },

    createAudioContext: function() {
        const AudioContext = window.AudioContext // Default
            || window.webkitAudioContext // old versions of Chrome or Safari
            || false

        if (AudioContext) {
            return new AudioContext
        }

        throw new Error("Web Audio API is not supported by your browser")
    }
};

document.addEventListener('connectUA', function(event) {
    console.log('init softphone');
    ctxSip.connectUA(event.detail.user);
});

document.addEventListener('disconnectUA', function(event) {
    console.log('disconnectUA');
    ctxSip.disconnectUA();
});

document.addEventListener('call', function(event) {
    console.log('call');
    ctxSip.call(event.detail.target, event.detail.silent, event.detail.callerId);
})

document.addEventListener('acceptCall', function(event) {
    console.log('acceptCall');
    ctxSip.acceptCall(event.detail.sessionId);
})

document.addEventListener('hangUp', function(event) {
    console.log('sipHangUp');
    ctxSip.hangUp(event.detail.sessionId);
})

document.addEventListener('mute', function(event) {
    console.log('mute');
    ctxSip.mute(event.detail.isMuted);
})

document.addEventListener('sendDTMF', function(event) {
    console.log('sendDTMF');
    ctxSip.sendDTMF(event.detail.symbol, event.detail.sessionId);
})

document.addEventListener('blindTransfer', function(event) {
    console.log('blindTransfer');
    ctxSip.blindTransfer(event.detail.target, event.detail.sessionId);
})

document.addEventListener('attendedTransferCall', function(event) {
    console.log('attendedTransferCall');
    ctxSip.attendedTransferCall(event.detail.replacementSessionId, event.detail.sessionId);
})

document.addEventListener('holdSession', function(event) {
    console.log('holdSession');
    const session = ctxSip.Sessions[event.detail.sessionId];
    if (session) {
        ctxSip.hold(session);
    }
})

document.addEventListener('unholdSession', function(event) {
    console.log('unholdSession');
    const session = ctxSip.Sessions[event.detail.sessionId];
    if (session) {
        ctxSip.unhold(session);
    }
})

document.addEventListener('createConference', function(event) {
    console.log('createConference')
    ctxSip.createConference()
})

document.addEventListener('splitConference', function(event) {
    console.log('splitConference')
    ctxSip.splitConference()
})

document.addEventListener('terminateConference', function(event) {
    console.log('terminateConference')
    ctxSip.terminateConference()
})

document.addEventListener('updateSpeakerDeviceId', function(event) {
    console.log('updateSpeakerDeviceId', event.detail.deviceId)
    ctxSip.speakerDeviceId = event.detail.deviceId
})

document.addEventListener('updateMicrophoneId', function(event) {
    console.log('updateMicrophoneId', event.detail.microphoneId)
    ctxSip.microphoneId = event.detail.microphoneId
})